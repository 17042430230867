'use strict';

Gri.module({
  name: 'header-beta',
  ieVersion: null,
  $el: $('.header-beta'),
  container: '.header-beta',
  fn: function () {
    
  }
});
